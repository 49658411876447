.carousel-wrapper {
	/* overflow: hidden; */
	height: max(600px, 55vh);
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.carousel {
	overflow-x: hidden;
	width: 100%;
	overflow: hidden;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel-item {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 500px;
	width: 650px;
	transition: all 0.3s ease;
	transform-origin: center center;
	perspective: 500px;
}

.carousel-item p {
	opacity: 1;
}

.carousel-item.focused {
	opacity: 1;
	height: 500px;
	width: 650px;
	z-index: 1;
}

.carousel-item.focused:hover {
	scale: 1.02;
	transition-duration: 400ms;
	transition-timing-function: cubic-bezier(0.7, 0, 1, 1);
}
.carousel-item.notFocused:hover {
	opacity: 1;
	transition: all;
	transition-duration: 600ms;
	transition-timing-function: cubic-bezier(0.075, 0.52, 0.165, 1);
}

.carousel-item.notFocused {
	scale: 0.8;
	opacity: 0.95;
}
.carousel-item.notFocused svg {
	display: none;
}

.carousel-item.notFocused h3 {
	display: none;
}
.carousel-item.notFocused h5 {
	display: none;
}

.carousel-item.notFocused h2 {
	width: 50%;
	top: 70%;
	left: 10%;
}
.carousel-item.notFocused img {
	height: 100%;
	width: 100%;
}
.carousel-item.notFocused span {
	pointer-events: none;
}

.arrow {
	position: absolute;
	z-index: 50;
	border: none;
	color: #333;
	cursor: pointer;
	outline: none;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 75ms;
}

.left-arrow {
	left: 24%;
	bottom: 30px;
	transform: translateY(-50%);
}

.right-arrow {
	right: 25%;
	bottom: 40px;
	transform: translateY(-50%);
}
@keyframes borderAnimation {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@media screen and (max-width: 700px) {
	.carousel-item.focused {
		width: 250px;
		height: 150px;
	}
}
@media screen and (max-width: 1520px) {
	.left-arrow {
		left: 14%;
		bottom: 30px;
		transform: translateY(-50%);
	}

	.right-arrow {
		right: 15%;
		bottom: 40px;
		transform: translateY(-50%);
	}
}
