@tailwind base;
@tailwind components;
@tailwind utilities;

@import './swiper-styles.css';

@layer base {
	html {
		font-family: 'Poppins', sans-serif;
		scroll-behavior: smooth !important;
		background: white;
	}
	h1 {
		@apply font-black text-[30px] md:text-[55px] lg:text-[62px] xl:text-[68px] 2xl:text-[85px];
		font-family: 'Poppins';
		font-weight: 700;
		line-height: 80%;
		letter-spacing: -0.05em;
		color: #ffffff;
	}
	h2 {
		@apply text-[20px] md:text-[30px] lg:text-[32px] 2xl:text-[35px];
		font-family: 'Poppins';
		letter-spacing: -0.05em;
		font-weight: 400;
		color: #ffffff;
	}
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 999999999;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.boxBG {
		border-radius: 45px;
		border: 8px solid #c6c6c6;
		background: linear-gradient(90deg, #161616 0%, #898989 100%);
	}

	.video-container {
		position: relative;
	}

	h3 {
		@apply text-[14px] lg:text-[17px]  2xl:text-[22px];
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		line-height: 110%;
		color: #ffffff;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
	.textOne {
		color: #97cc3c;
		text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		font-family: Poppins;
		font-size: 35px;
		font-style: normal;
		font-weight: 700;
		line-height: 29px; /* 82.857% */
		letter-spacing: -2px;
	}
	.textTwo {
		color: #fff;
		font-family: Poppins;
		font-size: 28px;
		font-style: normal;
		font-weight: 600;
		line-height: 29px;
		letter-spacing: -2px;
		text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}

	.hashTag {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 700;
		font-size: 25px;
		line-height: 134.4%;
	}

	.cardHeader {
		@apply lg:text-[14px] xl:text-[16px] 2xl:text-[20px];
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		text-align: center;
		letter-spacing: -0.04em;
	}
	.ceditText {
		@apply lg:text-[30px] xl:text-[35px] 2xl:text-[49px];
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 700;
		line-height: 120.5%;
		/* or 59px */
		text-align: center;
		letter-spacing: -0.04em;
	}
	.grid-container {
		display: grid;
		grid-template-columns: 30% 70%;
	}
	.biggerIndex {
		z-index: 1000;
	}
	.smallSpace {
		letter-spacing: -0.05em;
	}
	.terms-Conditions {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #000000;
	}

	/* styles.css */
	@keyframes draw {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}
	.lds-dual-ring {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.lds-dual-ring:after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid #b0d238;
		border-color: #b0d238 transparent #b0d238 transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes draw {
		to {
			stroke-dashoffset: 0;
		}
	}
	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ellipsis div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #b0d238;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.lds-ellipsis div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	.highlight {
		color: #70c242;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}

	.path-animation {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: draw 2s ease forwards;
	}

	.drawn {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: draw 3s ease-in infinite alternate; /* Use "alternate" to reverse the animation */
	}
	@keyframes pulse-border {
		0% {
			transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
			opacity: 1;
		}
		100% {
			transform: translateX(-50%) translateY(-50%) translateZ(0)
				scale(1.5);
			opacity: 0;
		}
	}
	.custom-cursor {
		cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iI2ZmZiIgaGVpZ2h0PSI0MHB4IiB3aWR0aD0iNDBweCIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDQxLjk5OSA0MS45OTkiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTM2LjA2OCwyMC4xNzZsLTI5LTIwQzYuNzYxLTAuMDM1LDYuMzYzLTAuMDU3LDYuMDM1LDAuMTE0QzUuNzA2LDAuMjg3LDUuNSwwLjYyNyw1LjUsMC45OTl2NDANCgljMCwwLjM3MiwwLjIwNiwwLjcxMywwLjUzNSwwLjg4NmMwLjE0NiwwLjA3NiwwLjMwNiwwLjExNCwwLjQ2NSwwLjExNGMwLjE5OSwwLDAuMzk3LTAuMDYsMC41NjgtMC4xNzdsMjktMjANCgljMC4yNzEtMC4xODcsMC40MzItMC40OTQsMC40MzItMC44MjNTMzYuMzM4LDIwLjM2MywzNi4wNjgsMjAuMTc2eiBNNy41LDM5LjA5NVYyLjkwNGwyNi4yMzksMTguMDk2TDcuNSwzOS4wOTV6Ii8+DQo8L3N2Zz4=),
			auto;
	}
	.gradientElementHover:hover {
		background: linear-gradient(49.18deg, #70c242 0%, #b0d238 100.03%);
	}
	.noScrollBar::-webkit-scrollbar {
		display: none;
	}
	.noScrollBar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	.hover-arrow img {
		transition: transform 0.3s ease-in-out; /* Transition property for smooth animation */
	}

	.clickedRotate {
		transform: rotate(90deg);
		transition: transform 0.2s ease-in;
	}
	.clickedRotatereverse {
		transform: rotate(0);
		transition: transform 0.2s ease-in;
	}

	.main {
		width: 100%;
		position: relative;
		padding: 0;
	}

	.content {
		width: 100%;
		position: relative;
		top: 0;
	}

	.card {
		position: relative;
		display: inline-block;
		transform: scale(0.8);
		opacity: 0.4;
		pointer-events: none;
	}
	.center-card {
		/* Add styles for the center card */
		transform: scale(1);
		opacity: 1;
		pointer-events: all;
		/* Adjust the scale value as needed */
	}

	.card-container {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.link-underline {
		border-bottom-width: 0;
		background-image: linear-gradient(transparent, transparent),
			linear-gradient(#fff, #ffff);
		background-size: 0 3px;
		background-position: 0 100%;
		background-repeat: no-repeat;
		transition: background-size 0.5s ease-in-out;
	}
	.link-underline:hover {
		color: #97cc3c;
		background-size: 100% 3px;
		background-position: 0 100%;
	}

	.scrolling-wrapper {
		overflow-x: scroll;
		width: 100%;
		overflow-y: hidden;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: mandatory;
	}
	.background-animate {
		background-size: 200%;

		-webkit-animation: gradientAnimation 15s ease infinite;
		-moz-animation: gradientAnimation 15s ease infinite;
		animation: gradientAnimation 15s ease infinite;
	}
	.background-animate2 {
		background-size: 150%;

		-webkit-animation: gradientAnimation 15s ease infinite;
		-moz-animation: gradientAnimation 15s ease infinite;
		animation: gradientAnimation 15s ease infinite;
	}

	@keyframes rotate {
		to {
			--angle: 360deg;
		}
	}

	@property --angle {
		syntax: '<angle>';
		initial-value: 0deg;
		inherits: false;
	}

	@keyframes gradientAnimation {
		0%,
		100% {
			background-position: 0% 100%;
		}
		50% {
			background-position: 100% 70%;
		}
	}

	.scrolling-wrapper::-webkit-scrollbar {
		display: none;
	}
	@media (min-width: 1600px) {
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
