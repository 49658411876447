.your-slider-class {
	position: relative;
	outline: none;
}
.your-slider-class:focus{
	outline: none;
}

.your-slider-class .slick-dots {
	position: absolute;
	bottom: 0px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	/* New styles to make dots appear in a flex column, justified between each other */
}

.your-slider-class .slick-dots li {
	margin-top: -10%;
	margin-right: 5px; /* Adjust the spacing between dots as needed */
}

.your-slider-class .slick-dots li button {
	content: '';
	font-size: 0px; /* Adjust the font size as needed */
	color: #333; /* Adjust the color as needed */
	background-color: #333; /* Adjust the background color as needed */
	border: none;
	width: 7px; /* Adjust the width of the dots as needed */
	height: 7px; /* Adjust the height of the dots as needed */
	border-radius: 50%;
	cursor: pointer;
	opacity: 0.3;
}

.your-slider-class .slick-dots li.slick-active button {
	opacity: 1;
	/* background-color: #84cc16; */
	background-color: #333;
	position: relative;
}
.your-slider-class .slick-dots li.slick-active button::before {
	content: '';
}
