.login-hidden {
	transform: translateX(-100%);
	opacity: 0;
	transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.login-visible {
	transform: translateX(0);
	opacity: 1;
	transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
