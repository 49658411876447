.swiper-button-prev, .swiper-button-next {
    color: #97cc3c !important;
    top: calc(100% + 2rem) !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -24px !important;
    /* left: 50% !important; */
    /* display: flex;
    align-items: center;
    transform-origin: "center"; */
}

.swiper-pagination-bullet {
    /* margin: auto; */
}

.cursor-pointer {
    cursor: pointer !important;
}

.swiper-pagination-bullet-active {
    background-color: #fff !important;
    border: 0.125rem solid #97cc3c;
    position: relative;
    width: 1rem !important;
    height: 1rem !important;
    transform: translateY(4px) !important;
}

.swiper-pagination-bullet-active::before {
    content: "";
    width: 8px !important;
    height: 8px !important;
    background-color: #97cc3c;
    position: absolute;
    bottom: calc(50% - 4px);
    left: calc(50% - 4px);
    border-radius: 999px;
}